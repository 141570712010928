@import '../../css/common_vars';

$filler-length: 40rem;
$bar-height: 6rem;
$bar-color: rgba($color_bright, 0.9);
$text-color: $color_main;

@keyframes animation-clock-warning-rotate {
  0% {
    transform: rotateZ(0deg) scale3d(1, 1, 1);
  }
  25% {
    transform: rotateZ(90deg) scale3d(1.25, 1.25, 1);
  }
  50% {
    transform: rotateZ(180deg) scale3d(1, 1, 1);
  }
  75% {
    transform: rotateZ(270deg) scale3d(1.25, 1.25, 1);
  }
  100% {
    transform: rotateZ(360deg) scale3d(1, 1, 1);
  }
}

.PointsBar {
  margin-left: 1rem;
  margin-right: 0.5rem;
  margin-bottom: 2rem;
  height: $bar-height;
  color: $text-color;

  .left-filler {
    position: absolute;
    height: $bar-height;
    width: $filler-length;
    left: -$filler-length;

    background-color: $bar-color;
  }

  .points-bar-body {
    background-color: $bar-color;
    position: relative;
    height: 100%;
    width: 100%;
    border-top-right-radius: $bar-height / 2;
    border-bottom-right-radius: $bar-height / 2;
    font-size: 2rem;
    font-weight: 700;

    .points-bar-content {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      font-size: 1.5rem;

      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    &.with-cup .points-bar-content {
      padding-left: $bar-height + 7.5rem;
    }

    &.with-clock .points-bar-content {
      padding-right: $bar-height + 7.5rem;
    }

    .points-bar-with-icon {
      display: flex;
      align-items: center;

      position: absolute;
      width: 7rem;
      height: $bar-height + 1rem;
      top: -0.5rem;

      background-repeat: no-repeat;
    }

    .cup-icon {
      left: -0.5rem;
      background-image: url('images/cup.svg');
      background-position: left;
      padding-left: $bar-height + 1.5rem;

      justify-content: flex-start;
    }

    .clock-icon {
      right: -0.5rem;
      //background-image: url('./images/clock-frame.svg');
      //background-position: right;
      width: 6.5rem;
      padding-right: $bar-height + 2rem;

      justify-content: flex-end;

      &::before {
        content: ' ';
        position: absolute;
        width: 7rem;
        height: 7rem;
        right: 0;
        top: 0;
        background-image: url('images/clock-frame.svg');
        background-repeat: no-repeat;
      }

      &::after {
        content: ' ';
        position: absolute;
        width: 7rem;
        height: 7rem;
        right: 0;
        top: 0;
        background-image: url('images/clock-hands.svg');
        background-repeat: no-repeat;
      }

      &.clock-warning {
        color: darken($color_error, 15%);

        &::before {
          animation: animation-clock-warning-rotate 2s linear 0s infinite normal backwards running;
        }
        &::after {
          animation: animation-clock-warning-rotate 1s linear 0s infinite normal backwards running;
        }

        &.clock-warning-stopped {
          &::before {
            animation-play-state: paused;
          }
          &::after {
            animation-play-state: paused;
          }
        }
      }
    }
  }
}