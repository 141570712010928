@import '../../css/common_vars';

.Button {
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  display: inline-block;
  padding: 1rem;
  font-weight: 700;
  font-size: 2rem;

  transition: background-color 500ms;

  color: #ffffff;
  background-color: $color_clickable;

  &.big {
    padding: 2rem;
    font-size: 3rem;
  }

  &.round {
    border-radius: 50%;
  }

  &.disabled {
    opacity: 0.6;
    cursor: default;

    &:hover {
      background-color: $color_clickable;
    }
  }

  &:hover {
    background-color: lighten($color_clickable, 5%);
  }
}