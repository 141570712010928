@import '../../vars';
@import '../../css/animations';

$meditation_color_main: #72A40A;
$meditation_color_accent: #8B0955;
$meditation_color_double_tap: #AFAE0B;

.outer.meditation > .middle {
  background-color: $meditation_color_main;
}

.MeditationExercise {
  .buttons-container .button {
    border-radius: 0.8em;

    p {
      border-radius: 0.3em;
    }
  }

  .starting-step {
    .exercise-image {
      box-sizing: border-box;
      width: 25em;

      background: #ffffff;

      border: 0.5em solid #fff;

      p {
        margin: 0.5em 0;
        font-size: 2em;
        font-weight: 700;

      }

      img {
        width: 100%;
        vertical-align: middle;
        border-radius: 0.4em;
      }
    }

    .buttons-container {
      display: flex;
      justify-content: space-between;

      .OldCard.button {
        width: 48%;
      }
    }
  }

  .tutorial-step {
    .exercise-instruction {
      font-size: 2.25em;
      height: 6em;
      margin-top: 0;

      display: flex;
      align-items: center;

      padding: 0;

      p {
        width: 100%;
        padding: 0 1em;
        margin: 0;

        animation: pop-out-small 750ms ease-in forwards;
      }
    }

    .tap-circle-container {
      .tap-circle {
        box-sizing: border-box;
        margin-top: 2em;
        border-radius: 50%;
        width: 35em;
        height: 35em;
        background-color: $meditation_color_accent;
        border: 1em white solid;
      }
    }

    .buttons-container {
      .OldCard.button {
        width: 80%;
      }
    }
  }

  .buttons-container {
    margin-top: 3em;

    .OldCard.button p {
      background: $meditation_color_accent;
    }

    &.exercise-duration {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      margin-top: 1em;

      .button {
        width: 30%;
      }
    }
  }

  .exercise-parameters {
    margin-top: 3em;
    width: 80%;
    font-size: 1.25em;
  }

  .summary {
    margin-top: 2em;
    width: 80%;
    font-size: 3em;

    p.smaller {
      font-size: 0.7em;
      margin: 0.5em 0;
    }
  }

  .tap-circle-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .tap-circle {
      box-sizing: border-box;
      margin-top: 4em;
      border-radius: 50%;
      width: 40em;
      height: 40em;
      background-color: $meditation_color_accent;
      border: 1em white solid;
    }
  }

  .tap-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    user-select: none;

    .tap-feedback {
      position: absolute;
      left: 0;
      top: 0;

      width: 0;
      height: 0;
      margin-left: 0;
      margin-top: 0;

      border-radius: 50%;
      background-color: $color_success;

      &.double-tap {
        background-color: $meditation_color_double_tap;
      }
    }
  }
}

.vertical .MeditationExercise {
  .starting-step {
    .buttons-container {
      display: block;

      .OldCard.button {
        width: 100%;
      }
    }
  }

  .PointsBar .OldCard.instruction {
    max-width: 18em;
  }
}