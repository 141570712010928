@import '../../css/common_vars';


.vertical .MessageScreen {
  padding-top: 1rem;

  .message {
    width: 95%;
    flex-wrap: wrap;

    .content {
      width: 100%;
      font-size: 1.2em;

      p {
        margin: 0.5em 0;
      }
    }

    .image {
      margin-top: 2rem;
      width: 50%;
      height: auto;
    }
  }
}

.MessageScreen {
  position: relative;
  padding-top: 10rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  user-select: none;

  &.full-image {
    padding-top: 2rem;

    .message {
      flex-direction: column;

      .image {
        width: 100%;
        height: auto;
        padding: 0;
        margin: 0;
      }
    }
  }

  .message {
    position: relative;
    padding: 0;
    width: 80%;
    margin-bottom: 2rem;

    color: #f1fcfd;
    background: #0F5E60;
    border-radius: 0;
    box-shadow: none;
    border: none;

    & > div {
      display: inline-block;
      vertical-align: middle;
    }

    .image {
      width: 33.333%;
      height: 100%;
      padding: 0;
      box-sizing: border-box;

      background-size: cover;
      background-position: center;

      img {
        opacity: 0;
        vertical-align: middle;
        width: 100%;
        //border: 0.5rem solid #f1fcfd;
        box-sizing: border-box;
        border-radius: 0;
      }
    }

    .content {
      width: 66.667%;
      padding: 1rem;
      box-sizing: border-box;

      p {
        font-size: 1.5em;
      }
    }

    .doors {
      position: absolute;
      width: 100%;
      height: 100%;

      .left-door, .right-door {
        position: absolute;
        height: 100%;
        width: 50%;
        box-sizing: border-box;
        border: solid $color_main 0;

        background-color: $color_bright;

        animation: message-door;
        animation-duration: 1000ms;
        animation-delay: 1000ms;
        animation-fill-mode: forwards;
      }

      .left-door {
        transform-origin: left;
        border-right-width: 0.25rem;
      }

      .right-door {
        left: 50%;
        transform-origin: right;
        border-left-width: 0.25rem;
      }

      @keyframes message-door {
        0% {transform: scaleX(1)}
        100% {transform: scaleX(0)}
      }
    }
  }

  .Button {
    min-width: 25%;
  }

  
}