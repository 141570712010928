.SprintBar {
  height: 3em;
  width: 100%;
  position: fixed;
  bottom: -1px;
  border: solid 1px #979797;
  background: linear-gradient(#fff, #eee);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5); }
  .SprintBar > div {
    font-size: 1.25em;
    line-height: 2.5em; }
  .SprintBar .logo {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1; }
    .SprintBar .logo img {
      padding: 0.2em 0.5em;
      height: 2em; }
  .SprintBar .help-button {
    margin: 0.15em 0 0.15em 0.5em;
    padding: 0 0.5em;
    font-weight: 700;
    font-size: 1.1em;
    display: inline-block;
    width: auto;
    height: 1.9em;
    line-height: 1.9em;
    cursor: pointer; }
