@import '../../vars';

.BasketsExercise {
  .answers-container {
    position: relative;
    padding-top: 2em;

    .timeFinished {
      z-index: 10;

      position: absolute;
      width: 40%;
      left: 30%;
      top: 30%;

      h1 {
        padding: 3em;
      }
    }

    .questions-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .question {
        //width: 26%;
        margin-bottom: 2em;

        &.react-draggable-dragging {
          .OldCard {
            cursor: grabbing;
          }
        }

        .OldCard {
          margin: 0;
          padding: 0 1em;
          height: 5em;

          font-size: 1.25em;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: grab;

          transition: opacity 200ms ease-in-out;

          &.disabled {
            opacity: 0.6;
            cursor: not-allowed;
          }

          &.hide {
            transition: none;
            cursor: default !important;
          }
        }
      }
    }

    .answers-row {
      text-align: justify;
      box-sizing: border-box;
      padding: 0;
      margin-bottom: 2em;
      display: flex;
      justify-content: space-between;

      .answer {
        text-align: center;
        margin: 0;
        width: 45%;
        cursor: default;
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);

        p {
          margin: 0.5em 0;
          font-size: 1.8em;
          font-weight: 700;
        }

        img {
          vertical-align: middle;
          width: 100%;
          border-radius: 5px;
          margin-bottom: 0;
        }
      }

      &.center {
        text-align: center;
      }

      .react-draggable-dragged {
        transition: transform 200ms ease-in-out;

        &.react-draggable-dragging {
          transition-duration: 0ms;
        }
      }

      .handle {
        //width is set in JavaScript
        display: inline-block;
        cursor: grab;
      }

      .OldCard {
        //width: 100%;
        //margin: 0;
        opacity: 1;

        transition: opacity 500ms ease-in-out;

        &.text {
          margin-top: 3em;
          height: auto;
          //transition: none;

          p {
            text-align: center;
            font-size: 1.5em;
            margin: 1.5em 0;

          }
        }

        &.answer-image {
          .front {
            transition: opacity 500ms ease-in-out;
            opacity: 1;
            height: 15em;

            .image {
              border: 6px solid white;
              border-radius: 5px;
              box-sizing: border-box;

              height: 100%;
              background-size: cover;
            }
          }

          .back {
            transition: opacity 500ms ease-in-out;
            opacity: 0;
            height: 15em;
            margin-top: -15em;

            .feedback {
              height: 100%;
              border: solid white 6px;
              border-radius: 5px;
              box-sizing: border-box;
              color: white;

              &.success {
                background: linear-gradient(rgba(0, 198, 24, 1), rgba(0, 198, 24, 0.8));
              }

              &.error {
                background: linear-gradient(rgba(255, 13, 0, 1), rgba(255, 13, 0, 0.8));
              }

              .icon {
                height: 100%;
                text-align: center;

                .feedback-icon {
                  vertical-align: middle;
                  display: inline-block;

                  font-size: 6em;
                }
              }

              .text {
                height: 100%;
                text-align: center;
                margin: 0;

                .feedback-text {
                  vertical-align: middle;
                  display: inline-block;

                  font-size: 2.5em;
                  font-weight: 700;

                  .smaller {
                    font-size: 0.5em;
                    font-weight: 300;
                  }
                }
              }
            }
          }

          &.show-feedback {
            .front {
              opacity: 0;
            }

            .back {
              opacity: 1;
            }
          }

        }
      }

      .react-draggable-dragging .OldCard.text {
        transition: none;
      }

      .fake-element {
        // Width set in JS
        display: inline-block;
        height: 1em;
      }
    }
  }

  .feedback-container {
    height: 8em;
    .feedback {
      border: 5px solid white;
      color: white;

      &.correct {
        background: $gradient_success;
      }

      &.incorrect {
        background: $gradient_error;
      }

      p {
        font-size: 1.5em;
        font-weight: 700;
      }
    }
  }

  .buttons-container {
    margin-top: -8em;
    z-index: 20;

    .center {
      z-index: 20;

      .next-button {
        z-index: 20;
      }
    }
  }
}